@font-face {
    font-family: 'Trade Gothic Next W01';
    src: url(../assets/fonts/8a6c3750-4927-4649-9dac-87049f5e9700.eot) format('eot');
}

@font-face {
    font-family: 'Trade Gothic Next W01';
    src: url(../assets/fonts/8a6c3750-4927-4649-9dac-87049f5e9700.eot);
    src: url(../assets/fonts/57625f96-ca80-4602-9644-ec1803cb3ba3.woff) format('woff'),
        url(../assets/fonts/6618d500-1727-4334-878e-1c4b95192f42.ttf) format('truetype'),
        url(../assets/fonts/01ebe944-ca6c-41db-a3ba-b7e2a90536d9.svg) format('svg');
}

@font-face {
    font-family: 'TradeGothicW01-BoldCn20 675334';
    src: url(../assets/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.eot) format('eot');
}

@font-face {
    font-family: 'TradeGothicW01-BoldCn20 675334';
    src: url(../assets/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.eot);
    src: url(../assets/fonts/1ba28851-f34b-4cb8-bf58-6a4b160ba249.woff2) format('woff2'),
        url(../assets/fonts/616c4c87-a077-43f4-a9f4-f01267c13818.ttf) format('truetype'),
        url(../assets/fonts/c901ad5f-a842-4549-a1f4-583a97f7e169.svg) format('svg');
}

@font-face {
    font-family: 'TradeGothicNextW01-Cond';
    src: url(../assets/fonts/5be4a815-5a22-4a8b-8a38-10467be39d2e.eot) format('eot');
}

@font-face {
    font-family: 'TradeGothicNextW01-Cond';
    src: url(../assets/fonts/5be4a815-5a22-4a8b-8a38-10467be39d2e.eot);
    src: url(../assets/fonts/eb20bf3e-7a5c-4c98-8bc5-f38c1b3dd9f2.woff) format('woff'),
        url(../assets/fonts/bbd9ed4b-21d6-4a35-a3eb-176c31258e03.ttf) format('truetype'),
        url(../assets/fonts/2b33a704-0f65-4c4c-94d0-2bd00c0151af.svg) format('svg');
}

@font-face {
    font-family: 'TradeGothicNextW01-Bold 693232';
    src: url(../assets/fonts/62cdf702-7113-4955-9b29-d803174650c7.eot) format('eot');
}

@font-face {
    font-family: 'TradeGothicNextW01-Bold 693232';
    src: url(../assets/fonts/62cdf702-7113-4955-9b29-d803174650c7.eot);
    src: url(../assets/fonts/a43fe47f-004d-4b6d-9a72-23de9e08f66e.woff) format('woff'),
        url(../assets/fonts/de22c287-e0dc-4287-8a0e-3f68a8788330.ttf) format('truetype'),
        url(../assets/fonts/d7c320de-0c26-418a-b1d2-ef600169db34.svg) format('svg');
}

@font-face {
    font-family: 'TradeGothicW01-BoldCn20 675334';
    src: url(../assets/fonts/257c802f-349c-4b4d-aefa-546d5de15ec6.eot);
    src: url(../assets/fonts/5fdc935e-9e30-442a-bbe9-8d887b858471.woff) format('woff'),
        url(../assets/fonts/616c4c87-a077-43f4-a9f4-f01267c13818.ttf) format('truetype'),
        url(../assets/fonts/c901ad5f-a842-4549-a1f4-583a97f7e169.svg) format('svg');
}

@font-face {
    font-family: 'TradeGothicNextW01-Cond';
    src: url(../assets/fonts/5be4a815-5a22-4a8b-8a38-10467be39d2e.eot) format('eot');
}

@font-face {
    font-family: 'TradeGothicNextW01-Cond';
    src: url(../assets/fonts/5be4a815-5a22-4a8b-8a38-10467be39d2e.eot);
    src: url(../assets/fonts/eb20bf3e-7a5c-4c98-8bc5-f38c1b3dd9f2.woff) format('woff'),
        url(../assets/fonts/bbd9ed4b-21d6-4a35-a3eb-176c31258e03.ttf) format('truetype'),
        url(../assets/fonts/2b33a704-0f65-4c4c-94d0-2bd00c0151af.svg) format('svg');
}

@font-face {
    font-family: 'TradeGothicNextW01-HvCn 707049';
    src: url(../assets/fonts/02688edd-1e75-49c8-a9a9-0c718ac03687.eot) format('eot');
}

@font-face {
    font-family: 'TradeGothicNextW01-HvCn 707049';
    src: url(../assets/fonts/02688edd-1e75-49c8-a9a9-0c718ac03687.eot);
    src: url(../assets/fonts/22f5a28a-c43f-43ad-bdf2-c1cb7abff9ec.woff) format('woff'),
        url(../assets/fonts/f49159c0-b257-4efa-b603-ff98fe015336.ttf) format('truetype'),
        url(../assets/fonts/b1cbaffb-5f24-4786-8c8d-6a1d0c3be8be.svg) format('svg');
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Trade Gothic Next W01', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
    font-family: 'TradeGothicW01-BoldCn20 675334';
    text-transform: uppercase;
}

h2 {
    text-transform: uppercase;
    font-family: 'TradeGothicW01-BoldCn20 675334';
}

p.small {
    font-family: 'TradeGothicNextW01-Cond';
    letter-spacing: 0.3px;
}

p.lead {
    font-family: 'TradeGothicNextW01-Bold 693232';
    letter-spacing: 0.2px;
}

h3 {
    font-family: 'TradeGothicW01-BoldCn20 675334';
    text-transform: uppercase;
}

.sitetitle {
    font-family: 'TradeGothicW01-BoldCn20 675334';
    text-transform: uppercase;
    font-size: 21px;
    line-height: 21px;
    letter-spacing: normal;
    flex: 1;
}

.documentDescription {
    font-family: 'TradeGothicNextW01-Bold 693232';
    font-size: 20px;
    line-height: 28px;
    margin: 0;
    letter-spacing: 0.2px;
    box-sizing: border-box;
}

p.overline,
span.overline {
    font-family: 'TradeGothicNextW01-Cond';
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1;
}

strong {
    font-family: 'TradeGothicNextW01-Bold 693229';
    letter-spacing: 0.2px;
}

strong.bt {
    font-family: 'TradeGothicNextW01-Bold 693229';
    letter-spacing: 0.2px;
}

strong.st {
    font-family: 'TradeGothicNextW01-Bold 693232';
    letter-spacing: 0.3px;
}

p.overline2 {
    font-family: 'TradeGothicNextW01-Cond';
    text-transform: uppercase;
    letter-spacing: 1px;
}

h6 {
    font-family: 'TradeGothicNextW01-HvCn 707049';
    letter-spacing: 0.4px;
}

h4 {
    font-family: 'TradeGothicW01-BoldCn20 675334';
    font-weight: 400;
    text-transform: uppercase;
}

h5 {
    font-family: 'TradeGothicNextW01-HvCn 707049';
    letter-spacing: 0.5px;
}

p.ol2 {
    font-family: 'TradeGothicNextW01-Cond';
    text-transform: uppercase;
    letter-spacing: 1px;
}

p.ol1 {
    text-transform: uppercase;
    font-family: 'TradeGothicNextW01-Cond';
    letter-spacing: 1px;
}

p.ol3 {
    text-transform: uppercase;
    font-family: 'TradeGothicNextW01-Cond';
    letter-spacing: 1.1px;
}

p.ol4 {
    text-transform: uppercase;
    font-family: 'TradeGothicNextW01-Cond';
    letter-spacing: 1.1px;
}

p.ol6 {
    text-transform: uppercase;
    font-family: 'TradeGothicNextW01-Cond';
    letter-spacing: 1.1px;
}

li.st {
    font-family: 'TradeGothicNextW01-Cond';
    letter-spacing: 0.3px;
}

span.quote {
    font-family: 'TradeGothicNextW01-Bold 693232';
    letter-spacing: 0.2px;
}

p.pl1 {
    font-family: 'TradeGothicNextW01-Bold 693232';
    letter-spacing: 0.2px;
}

p.pl2 {
    font-family: 'TradeGothicNextW01-Bold 693232';
    letter-spacing: 0.2px;
}

.title {
    font-family: 'TradeGothicW01-BoldCn20 675334';
    line-height: 45px;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: normal;
    padding: 45px 0px 36px 0px;

    font-size: 45px;
}

.MuiAlert-message {
    white-space: pre-line;
}
