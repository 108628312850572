footer {
    margin-top: 2em;
    font-family: 'TradeGothicNextW01-Cond';
    font-size: 14.25px;
    text-transform: none;
    font-weight: normal;
    line-height: 21px;
    letter-spacing: 0.3px;
    padding: 0 0 18px 0;
}

footer a {
    text-decoration: none;
}

@media (min-width: 540px) {
    footer {
        font-size: 15px;
    }
}

footer form {
    margin: 0;
}

footer section {
    padding: 1em 0;
    border-top: 1px solid #e4dfd8;
}

footer section a {
    color: #ab9f91;
}

footer section a:hover,
footer section a:focus {
    text-decoration: none;
    color: #362c2c;
}

footer section p,
footer section span {
    color: #ab9f91;
}

footer section .last {
    float: right;
}

@media (min-width: 900px) {
    footer section .row {
        margin: 0 auto;
    }
}

footer section .row > ul > li {
    float: left;
    width: 100%;
}

@media (min-width: 680px) {
    footer section .row > ul > li {
        width: 31.31313131%;
        margin-left: 3.03030303%;
    }
}

footer section .row > ul > li:first-child {
    margin-left: 0;
}

footer section .row.bottom {
    position: relative;
}

footer section .row.bottom:before,
footer section .row.bottom:after {
    content: ' ';
    display: table;
}

footer section .row.bottom:after {
    clear: both;
}

footer section .row.bottom > div {
    float: left;
    display: table-cell;
}

footer section .row.bottom .left {
}

@media (min-width: 680px) {
    footer section .row.bottom .left {
    }
}

footer section .row.bottom .left > div {
    float: left;
}

@media (min-width: 680px) {
    footer section .row.bottom .left > .address {
    }
}

@media (min-width: 680px) {
    footer section .row.bottom .left > .location {
    }
}

footer section .logo {
    background-image: url(../../../assets/img/logo_footer.svg);
    background-repeat: no-repeat;
    background-position: right;
}

@media (min-width: 680px) {
    footer section .row.bottom .right {
        background-image: url(../../../assets/img/logo_footer.svg);
    }
}

footer section .title {
    font-family: 'TradeGothicNextW01-HvCn_707049';
    font-size: 14.25px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0.45px;
    line-height: 21px;
    padding: 12px 0 0 0;
    margin-top: 0;
    padding-top: 0;
    color: #786a60;
}

@media (min-width: 540px) {
    footer section .title {
        font-size: 15px;
    }
}

footer section .social-icons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 20px 0;
}

@media (min-width: 680px) {
    footer section .social-icons {
        margin: 0;
        margin-top: 40px;
    }
}

footer section .social-icons > a {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
}

@media (min-width: 680px) {
    footer section .social-icons > a {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    }
}

footer section .social-icons > a:before {
    float: left;
    margin-top: -4px;
    content: '';
    display: block;
    width: 32px;
    height: 32px;
}

footer section ul {
    list-style-type: none;
    padding: 0;
    margin-top: 0;
}

footer section ul.link-list > li:after {
    content: '.';
    color: #aa9d91;
}

footer section ul.link-list > li > a {
    padding: 0;
}

footer section ul.link-list > li:first-child > a {
    padding-left: 0;
}

footer section ul.link-list > li:last-child:after {
    content: none;
}

footer section ul > li {
    display: inline-block;
}

footer section input {
    font-family: 'TradeGothicNextW01-Cond';
    font-size: 14.25px;
    text-transform: none;
    font-weight: normal;
    line-height: 21px;
    letter-spacing: 0.3px;
    padding: 0 0 18px 0;
    display: block;
    float: left;
    box-sizing: border-box;
    border: 1px solid #cac2b9;
    padding: 2px 10px 3px 10px;
    width: 70%;
    line-height: 26px;
    height: 33px;
}

@media (min-width: 540px) {
    footer section input {
        font-size: 15px;
    }
}

footer section input:focus {
    border-color: #362c2c;
    color: #362c2c;
    outline: none;
}

footer section input[type='submit'] {
    background: #504741;
    border: 1px solid #504741;
    color: #fff;
    margin-left: -1px;
    width: 30%;
}

footer section ul,
footer section span {
    display: block;
}

footer section #footer-location {
    display: block;
    margin: 20px 0;
}

footer section #footer-location:before {
    content: '';
    float: left;
    margin-top: -4px;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2018.1.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%0A%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20version%3D%221.1%22%20id%3D%22Layer_1%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2034%2034%22%20enable-background%3D%22new%200%200%2034%2034%22%20xml%3Aspace%3D%22preserve%22%20width%3D%2234%22%20height%3D%2234%22%3E%0A%3Csymbol%20id%3D%22Deleted_Symbol%22%20viewBox%3D%220%20-10%2010%2010%22%3E%0A%09%3Cpolygon%20opacity%3D%220.1%22%20fill%3D%22%2300BBFF%22%20points%3D%2210%2C-10%200%2C-10%200%2C0%2010%2C0%20%20%22/%3E%0A%09%3Cpolygon%20display%3D%22none%22%20fill%3D%22none%22%20points%3D%220%2C0%2010%2C0%2010%2C-10%200%2C-10%20%20%22/%3E%0A%3C/symbol%3E%0A%3Cpath%20fill%3D%22%23AA9D91%22%20d%3D%22M17%2C6c-4.4%2C0-8%2C3.6-8%2C8s8%2C14%2C8%2C14s8-9.6%2C8-14S21.4%2C6%2C17%2C6z%20M17%2C17c-1.7%2C0-3-1.3-3-3s1.3-3%2C3-3s3%2C1.3%2C3%2C3%20%20S18.7%2C17%2C17%2C17z%22/%3E%0A%3C/svg%3E%0A');
    background-repeat: no-repeat;
    background-size: 32px;
    display: block;
    width: 32px;
    height: 32px;
}

footer section #footer-location:hover:before {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2018.1.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%0A%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20version%3D%221.1%22%20id%3D%22Layer_1%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2034%2034%22%20enable-background%3D%22new%200%200%2034%2034%22%20xml%3Aspace%3D%22preserve%22%20width%3D%2234%22%20height%3D%2234%22%3E%0A%3Csymbol%20id%3D%22Deleted_Symbol%22%20viewBox%3D%220%20-10%2010%2010%22%3E%0A%09%3Cpolygon%20opacity%3D%220.1%22%20fill%3D%22%2300BBFF%22%20points%3D%2210%2C-10%200%2C-10%200%2C0%2010%2C0%20%20%22/%3E%0A%09%3Cpolygon%20display%3D%22none%22%20fill%3D%22none%22%20points%3D%220%2C0%2010%2C0%2010%2C-10%200%2C-10%20%20%22/%3E%0A%3C/symbol%3E%0A%3Cpath%20fill%3D%22%23504741%22%20d%3D%22M17%2C6c-4.4%2C0-8%2C3.6-8%2C8s8%2C14%2C8%2C14s8-9.6%2C8-14S21.4%2C6%2C17%2C6z%20M17%2C17c-1.7%2C0-3-1.3-3-3s1.3-3%2C3-3s3%2C1.3%2C3%2C3%20%20S18.7%2C17%2C17%2C17z%22/%3E%0A%3C/svg%3E%0A');
    background-repeat: no-repeat;
    display: block;
    background-size: 32px;
}

footer section #footer-facebook:before {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2018.1.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%0A%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20version%3D%221.1%22%20id%3D%22Layer_1%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2034%2034%22%20enable-background%3D%22new%200%200%2034%2034%22%20xml%3Aspace%3D%22preserve%22%20width%3D%2234%22%20height%3D%2234%22%3E%0A%3Cpath%20fill%3D%22%23AA9D91%22%20d%3D%22M25.8%2C6H7.2C6.5%2C6%2C6%2C6.5%2C6%2C7.2v18.7C6%2C26.5%2C6.5%2C27%2C7.2%2C27H17v-8h-3v-3h3v-2.6c0-2.7%2C1.9-4.2%2C4.3-4.2%20%20c1.2%2C0%2C1.7%2C0.1%2C2.7%2C0.1V12h-1.9c-1.3%2C0-2.1%2C0.8-2.1%2C1.7V16h3.6l-0.4%2C3H20v8h5.8c0.6%2C0%2C1.2-0.5%2C1.2-1.2V7.2C27%2C6.5%2C26.5%2C6%2C25.8%2C6z%22/%3E%0A%3C/svg%3E%0A');
    background-repeat: no-repeat;
    display: block;
    background-size: 32px;
}

footer section #footer-facebook:hover:before {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2018.1.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%0A%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20version%3D%221.1%22%20id%3D%22Layer_1%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2034%2034%22%20enable-background%3D%22new%200%200%2034%2034%22%20xml%3Aspace%3D%22preserve%22%20width%3D%2234%22%20height%3D%2234%22%3E%0A%3Cpath%20fill%3D%22%23504741%22%20d%3D%22M25.8%2C6H7.2C6.5%2C6%2C6%2C6.5%2C6%2C7.2v18.7C6%2C26.5%2C6.5%2C27%2C7.2%2C27H17v-8h-3v-3h3v-2.6c0-2.7%2C1.9-4.2%2C4.3-4.2%20%20c1.2%2C0%2C1.7%2C0.1%2C2.7%2C0.1V12h-1.9c-1.3%2C0-2.1%2C0.8-2.1%2C1.7V16h3.6l-0.4%2C3H20v8h5.8c0.6%2C0%2C1.2-0.5%2C1.2-1.2V7.2C27%2C6.5%2C26.5%2C6%2C25.8%2C6z%22/%3E%0A%3C/svg%3E%0A');
    background-repeat: no-repeat;
    display: block;
    background-size: 32px;
}

footer section #footer-twitter:before {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2018.1.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%0A%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20version%3D%221.1%22%20id%3D%22Layer_1%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2034%2034%22%20enable-background%3D%22new%200%200%2034%2034%22%20xml%3Aspace%3D%22preserve%22%20width%3D%2234%22%20height%3D%2234%22%3E%0A%3Cpath%20fill%3D%22%23AA9D91%22%20d%3D%22M28.6%2C10.9c-0.8%2C0.3-1.6%2C0.6-2.5%2C0.7C27%2C11%2C27.7%2C10.1%2C28%2C9.1c-0.8%2C0.5-1.8%2C0.9-2.8%2C1.1%20%20c-0.8-0.8-1.9-1.4-3.2-1.4c-2.4%2C0-4.3%2C1.9-4.3%2C4.3c0%2C0.3%2C0%2C0.7%2C0.1%2C1C14.2%2C14%2C11%2C12.2%2C8.9%2C9.6c-0.4%2C0.6-0.6%2C1.4-0.6%2C2.2%20%20c0%2C1.5%2C0.8%2C2.8%2C1.9%2C3.6c-0.7%2C0-1.4-0.2-2-0.5c0%2C0%2C0%2C0%2C0%2C0.1c0%2C2.1%2C1.5%2C3.9%2C3.5%2C4.3c-0.4%2C0.1-0.7%2C0.2-1.1%2C0.2c-0.3%2C0-0.6%2C0-0.8-0.1%20%20c0.6%2C1.7%2C2.2%2C3%2C4.1%2C3c-1.5%2C1.2-3.4%2C1.9-5.4%2C1.9c-0.4%2C0-0.7%2C0-1-0.1c1.9%2C1.2%2C4.2%2C1.9%2C6.6%2C1.9c8%2C0%2C12.3-6.6%2C12.3-12.3%20%20c0-0.2%2C0-0.4%2C0-0.6C27.3%2C12.5%2C28%2C11.7%2C28.6%2C10.9z%22/%3E%0A%3C/svg%3E%0A');
    background-repeat: no-repeat;
    display: block;
    background-size: 32px;
}

footer section #footer-twitter:hover:before {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2018.1.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%0A%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20version%3D%221.1%22%20id%3D%22Layer_1%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2034%2034%22%20enable-background%3D%22new%200%200%2034%2034%22%20xml%3Aspace%3D%22preserve%22%20width%3D%2234%22%20height%3D%2234%22%3E%0A%3Cpath%20fill%3D%22%23504741%22%20d%3D%22M28.6%2C10.9c-0.8%2C0.3-1.6%2C0.6-2.5%2C0.7C27%2C11%2C27.7%2C10.1%2C28%2C9.1c-0.8%2C0.5-1.8%2C0.9-2.8%2C1.1%20%20c-0.8-0.8-1.9-1.4-3.2-1.4c-2.4%2C0-4.3%2C1.9-4.3%2C4.3c0%2C0.3%2C0%2C0.7%2C0.1%2C1C14.2%2C14%2C11%2C12.2%2C8.9%2C9.6c-0.4%2C0.6-0.6%2C1.4-0.6%2C2.2%20%20c0%2C1.5%2C0.8%2C2.8%2C1.9%2C3.6c-0.7%2C0-1.4-0.2-2-0.5c0%2C0%2C0%2C0%2C0%2C0.1c0%2C2.1%2C1.5%2C3.9%2C3.5%2C4.3c-0.4%2C0.1-0.7%2C0.2-1.1%2C0.2c-0.3%2C0-0.6%2C0-0.8-0.1%20%20c0.6%2C1.7%2C2.2%2C3%2C4.1%2C3c-1.5%2C1.2-3.4%2C1.9-5.4%2C1.9c-0.4%2C0-0.7%2C0-1-0.1c1.9%2C1.2%2C4.2%2C1.9%2C6.6%2C1.9c8%2C0%2C12.3-6.6%2C12.3-12.3%20%20c0-0.2%2C0-0.4%2C0-0.6C27.3%2C12.5%2C28%2C11.7%2C28.6%2C10.9z%22/%3E%0A%3C/svg%3E%0A');
    background-repeat: no-repeat;
    display: block;
    background-size: 32px;
}

footer section #footer-youtube:before {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2018.1.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%0A%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20version%3D%221.1%22%20id%3D%22Layer_1%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2034%2034%22%20enable-background%3D%22new%200%200%2034%2034%22%20xml%3Aspace%3D%22preserve%22%20width%3D%2234%22%20height%3D%2234%22%3E%0A%3Csymbol%20id%3D%22Deleted_Symbol%22%20viewBox%3D%220%20-10%2010%2010%22%3E%0A%09%3Cpolygon%20opacity%3D%220.1%22%20fill%3D%22%2300BBFF%22%20points%3D%2210%2C-10%200%2C-10%200%2C0%2010%2C0%20%20%22/%3E%0A%09%3Cpolygon%20display%3D%22none%22%20fill%3D%22none%22%20points%3D%220%2C0%2010%2C0%2010%2C-10%200%2C-10%20%20%22/%3E%0A%3C/symbol%3E%0A%3Cpath%20fill%3D%22%23AA9D91%22%20d%3D%22M27.8%2C12.5c0%2C0-0.2-1.6-0.9-2.3c-0.9-0.9-1.9-0.9-2.3-1c-3.2-0.2-8-0.2-8-0.2h0c0%2C0-4.8%2C0-8%2C0.2%20%20C8%2C9.3%2C7%2C9.3%2C6.1%2C10.2c-0.7%2C0.7-0.9%2C2.3-0.9%2C2.3S5%2C14.3%2C5%2C16.1v1.7c0%2C1.8%2C0.2%2C3.7%2C0.2%2C3.7s0.2%2C1.6%2C0.9%2C2.3c0.9%2C0.9%2C2%2C0.9%2C2.5%2C1%20%20c1.8%2C0.2%2C7.8%2C0.2%2C7.8%2C0.2s4.8%2C0%2C8.1-0.2c0.4-0.1%2C1.4-0.1%2C2.3-1c0.7-0.7%2C0.9-2.3%2C0.9-2.3s0.2-1.8%2C0.2-3.7v-1.7%20%20C28%2C14.3%2C27.8%2C12.5%2C27.8%2C12.5z%20M14%2C20l0-7l7%2C3.5L14%2C20z%22/%3E%0A%3C/svg%3E%0A');
    background-repeat: no-repeat;
    display: block;
    background-size: 32px;
}

footer section #footer-youtube:hover:before {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2018.1.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%0A%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20version%3D%221.1%22%20id%3D%22Layer_1%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2034%2034%22%20enable-background%3D%22new%200%200%2034%2034%22%20xml%3Aspace%3D%22preserve%22%20width%3D%2234%22%20height%3D%2234%22%3E%0A%3Csymbol%20id%3D%22Deleted_Symbol%22%20viewBox%3D%220%20-10%2010%2010%22%3E%0A%09%3Cpolygon%20opacity%3D%220.1%22%20fill%3D%22%2300BBFF%22%20points%3D%2210%2C-10%200%2C-10%200%2C0%2010%2C0%20%20%22/%3E%0A%09%3Cpolygon%20display%3D%22none%22%20fill%3D%22none%22%20points%3D%220%2C0%2010%2C0%2010%2C-10%200%2C-10%20%20%22/%3E%0A%3C/symbol%3E%0A%3Cpath%20fill%3D%22%23504741%22%20d%3D%22M27.8%2C12.5c0%2C0-0.2-1.6-0.9-2.3c-0.9-0.9-1.9-0.9-2.3-1c-3.2-0.2-8-0.2-8-0.2h0c0%2C0-4.8%2C0-8%2C0.2%20%20C8%2C9.3%2C7%2C9.3%2C6.1%2C10.2c-0.7%2C0.7-0.9%2C2.3-0.9%2C2.3S5%2C14.3%2C5%2C16.1v1.7c0%2C1.8%2C0.2%2C3.7%2C0.2%2C3.7s0.2%2C1.6%2C0.9%2C2.3c0.9%2C0.9%2C2%2C0.9%2C2.5%2C1%20%20c1.8%2C0.2%2C7.8%2C0.2%2C7.8%2C0.2s4.8%2C0%2C8.1-0.2c0.4-0.1%2C1.4-0.1%2C2.3-1c0.7-0.7%2C0.9-2.3%2C0.9-2.3s0.2-1.8%2C0.2-3.7v-1.7%20%20C28%2C14.3%2C27.8%2C12.5%2C27.8%2C12.5z%20M14%2C20l0-7l7%2C3.5L14%2C20z%22/%3E%0A%3C/svg%3E%0A');
    background-repeat: no-repeat;
    display: block;
    background-size: 32px;
}

footer section #footer-instagram:before {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2034%2034%22%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill%3A%23aa9d91%3B%7D.b%7Bfill%3Anone%3B%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Einstagram_sft%3C/title%3E%3Cpath%20class%3D%22a%22%20d%3D%22M21%2C5H12C7.42%2C5%2C5%2C7.42%2C5%2C12v9c0%2C4.58%2C2.42%2C7%2C7%2C7h9c4.58%2C0%2C7-2.42%2C7-7V12C28%2C7.42%2C25.58%2C5%2C21%2C5Zm5%2C16c0%2C3.46-1.54%2C5-5%2C5H12c-3.46%2C0-5-1.54-5-5V12c0-3.46%2C1.54-5%2C5-5h9c3.46%2C0%2C5%2C1.54%2C5%2C5ZM16.5%2C11A5.5%2C5.5%2C0%2C1%2C0%2C22%2C16.5%2C5.51%2C5.51%2C0%2C0%2C0%2C16.5%2C11Zm0%2C9A3.5%2C3.5%2C0%2C1%2C1%2C20%2C16.5%2C3.5%2C3.5%2C0%2C0%2C1%2C16.5%2C20Zm6-11A1.5%2C1.5%2C0%2C1%2C0%2C24%2C10.5%2C1.5%2C1.5%2C0%2C0%2C0%2C22.5%2C9Z%22/%3E%3Crect%20class%3D%22b%22%20width%3D%2234%22%20height%3D%2234%22/%3E%3C/svg%3E%0A');
    background-repeat: no-repeat;
    display: block;
    background-size: 32px;
}

footer section #footer-instagram:hover:before {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2034%2034%22%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill%3A%23504741%3B%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Einstagram_drk%3C/title%3E%3Cpath%20class%3D%22a%22%20d%3D%22M21%2C5H12C7.42%2C5%2C5%2C7.42%2C5%2C12v9c0%2C4.58%2C2.42%2C7%2C7%2C7h9c4.58%2C0%2C7-2.42%2C7-7V12C28%2C7.42%2C25.58%2C5%2C21%2C5Zm5%2C16c0%2C3.46-1.54%2C5-5%2C5H12c-3.46%2C0-5-1.54-5-5V12c0-3.46%2C1.54-5%2C5-5h9c3.46%2C0%2C5%2C1.54%2C5%2C5ZM16.5%2C11A5.5%2C5.5%2C0%2C1%2C0%2C22%2C16.5%2C5.51%2C5.51%2C0%2C0%2C0%2C16.5%2C11Zm0%2C9A3.5%2C3.5%2C0%2C1%2C1%2C20%2C16.5%2C3.5%2C3.5%2C0%2C0%2C1%2C16.5%2C20Zm6-11A1.5%2C1.5%2C0%2C1%2C0%2C24%2C10.5%2C1.5%2C1.5%2C0%2C0%2C0%2C22.5%2C9Z%22/%3E%3C/svg%3E%0A');
    background-repeat: no-repeat;
    display: block;
    background-size: 32px;
}

footer section:before,
footer section:after {
    content: ' ';
    display: table;
}

footer section:after {
    clear: both;
}
